import styled, { css } from 'styled-components'
import { theme } from '@provi/provi-components'

interface IContent {
  imagePosition: 'left' | 'right'
  imageOffsetX: number
  minHeight?: string
  isVariant?: boolean
}

interface IDarkVariant {
  isDark?: boolean
}

export const SectionWrapper = styled.div<IDarkVariant>`
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  background-color: ${({ isDark }) => (isDark ? ` ${theme.colors.bluePrimaryDark}` : 'default')};
`

export const Container = styled.div<IContent>`
  max-width: ${({ imageOffsetX }) => (imageOffsetX ? '1164px' : '1064px')};
  display: flex;
  gap: 24px;
  padding: 24px;
  flex-direction: ${({ imagePosition }) => (imagePosition === 'left' ? 'row' : 'row-reverse')};
  align-items: center;
  width: ${({ imageOffsetX }) => (imageOffsetX ? `calc(100% + ${imageOffsetX}px)` : '100%')};
  min-height: ${({ minHeight }) => minHeight || '600px'};

  ${({ imagePosition, imageOffsetX }) =>
    imageOffsetX && imagePosition === 'left'
      ? css`
          margin-left: -${imageOffsetX}px;
        `
      : css`
          margin-right: -${imageOffsetX}px;
        `}

  @media (max-width: 990px) {
    height: fit-content;
    width: 100%;
    padding: 24px;
    flex-direction: ${({ isVariant }) => (isVariant ? 'column-reverse' : 'column')};
    margin: 0 auto;
  }
`
export const FirstPart = styled.div`
  img {
    border-radius: 16px;
  }
`

export const SecondPart = styled.div`
  width: 496px;
  max-width: 50%;

  @media (max-width: 990px) {
    width: 100%;
    max-width: 100%;
  }
`

export const Tag = styled.div<IDarkVariant>`
  font-family: 'Montserrat';
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.015em;
  text-transform: uppercase;
  color: ${({ isDark }) => (isDark ? `${theme.colors.greyLight}` : '#2647d7')};
  margin-bottom: 24px;

  ${theme.breakpoints.down('md')} {
    font-size: 13.2px;
    line-height: 20px;
    letter-spacing: 0.02em;
    margin-bottom: 12px;
  }
`

export const Heading2 = styled.h2<IDarkVariant>`
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 39.06px;
  line-height: 48px;
  color: ${({ isDark }) => (isDark ? `${theme.colors.greyLight}` : '#000000')};
  margin-bottom: 40px;

  ${theme.breakpoints.down('md')} {
    font-size: 27.37px;
    line-height: 40px;
    letter-spacing: 0.0025em;
    margin-bottom: 17px;
  }
`

export const Content = styled.div<IDarkVariant>`
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.005em;
  color: ${({ isDark }) => (isDark ? `${theme.colors.greyLight}` : '#000000')};

  ${theme.breakpoints.down('md')} {
    font-size: 15.84px;
    line-height: 24px;
    letter-spacing: 0.005em;
  }
`
