import styled, { css } from 'styled-components'
import { theme } from '@provi/provi-components'

interface IContainer {
  backgroundColor: string
  backgroundURL: string
  hasBreadCrumbs?: boolean
  isVariant?: boolean
}

interface IBannerTitle {
  isVariant?: boolean
}

export const Container = styled.header<IContainer>`
  display: flex;
  flex-direction: column;
  background-image: ${({ backgroundURL }) => `url('${backgroundURL}')`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 140px;
  padding: 65px 0 0;

  ${theme.breakpoints.down('md')} {
    background-image: none;
    height: 76px;
    justify-content: flex-start;
    padding: 31px 0 0;
  }
  ${({ hasBreadCrumbs, isVariant }) =>
    hasBreadCrumbs &&
    css`
      padding: 13px 0 0;
      height: 140px;
      > div {
        width: 100%;
        max-width: 1064px;
        margin: 0 auto;
        padding-left: 24px;

        ${() =>
          isVariant &&
          css`
            max-width: 333px;
            margin: 0 0 0 calc(50% - 200px);
            padding-left: 0;
          `}
        margin-bottom: 44px;
      }

      ${theme.breakpoints.down('md')} {
        padding: 8px 0 0;
        height: ${() => (isVariant ? '140px' : '100px')};

        > div {
          margin: 0 0 26px 24px;
          padding-left: 0;
          max-width: fit-content;

          span {
            font-size: 11px;
          }
        }
      }
    `}

  background-color: ${({ backgroundColor }) => backgroundColor};
`

export const BannerTitle = styled.h1<IBannerTitle>`
  display: flex;
  font-weight: 500;
  font-size: 31.25px;
  line-height: 40px;
  margin: 0 auto;
  max-width: 1064px;
  width: 100%;
  padding-left: 24px;
  color: ${theme.colors.bluePrimaryDark};

  ${({ isVariant }) =>
    !!isVariant &&
    css`
      max-width: fit-content;
      margin: 0 0 0 calc(50% - 200px);
      padding-left: 0;
    `}

  ${theme.breakpoints.down('md')} {
    max-width: 312px;
    font-size: 27.37px;
    min-width: 100px;
    margin: 0 0 0 24px;
    padding-left: 0;
  }
`
export const A = styled.a`
  text-decoration: none;
  color: black !important;
  text-decoration: underline;
`
