import axios, { AxiosResponse } from 'axios'
import { ISendPartnerForm, ILeadBody, IJoinEmailList } from 'types'
import { ROOT_URL } from '~/constants'

export const api = axios.create({
  baseURL: ROOT_URL?.MS_SITE || '',
})

const TOKEN_FRONT_END =
  'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VySWQiOjEsImVtYWlsIjoiam9hb0Bwcm92aS5jb20uYnIiLCJleHAiOiIzNmQifQ.yBOqt3uFDYs7byxW4ZczfOzxlUPgC8CZcWhlhPKBtn4'

api.interceptors.request.use(
  (config) => {
    config.headers.Authorization = TOKEN_FRONT_END

    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

export const joinEmailList = async ({ email }: IJoinEmailList) => {
  try {
    const { data } = await api.post(`${ROOT_URL?.MS_SITE}/form/lead-marketing-slack`, {
      name: 'Lead Newsletter',
      email,
      message: 'Um novo preenchimento de newsletter foi efetuado',
    })
    return data
  } catch (error: any) {
    return error?.response?.data
  }
}

export const sendPartnerForm = async (body: ISendPartnerForm): Promise<any> => {
  const {
    firstName,
    lastName,
    email,
    phone,
    companyName,
    companyWebsite,
    cnpj,
    averageTicket,
    salesModel,
    estimatedStudents,
    nextLaunchDate,
  } = body

  try {
    return await axios.post(
      `${ROOT_URL?.MS_INTEGRATIONS_URL}/crm-integrations`,
      {
        purpose: 'CREATE_LEAD',
        data: {
          firstName,
          lastName,
          email,
          phone: `${phone?.replace(/\D/g, '')}`,
          company: companyName,
          website: companyWebsite,
          Average_Ticket__c: averageTicket?.value,
          Sales_Model__c: salesModel?.value,
          Estimated_Students__c: estimatedStudents?.value,
          Next_Launch_Date__c: nextLaunchDate,
          cnpj,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${ROOT_URL?.MS_INTEGRATIONS_TOKEN}`,
        },
      }
    )
  } catch (error: any) {
    return error?.response
  }
}

export const sendCaseForm = async (body: ILeadBody): Promise<AxiosResponse> => {
  const { name, email, pageName } = body

  try {
    return await api.post(`${ROOT_URL?.MS_SITE}/form/lead-marketing-slack`, {
      name,
      email,
      message: `Um novo preenchimento de cases de sucesso foi efetuado em ${pageName}`,
    })
  } catch (error: any) {
    return error?.response
  }
}
