import { useEffect, useMemo } from 'react'
import { useRouter } from 'next/router'
import NextLink from 'next/link'
import {
  InputFieldV3 as Input,
  ButtonV3 as Button,
  SelectorV3 as Select,
  TextButton,
  ProgressBar,
  theme,
} from '@provi/provi-components'
import { Container, ButtonWrapper, InputForm, SuccessMessage, TOSWarning, TOSLink, ErrorMessage } from './styles'

import {
  canGoToNextPage,
  companyNameHasError,
  emailHasError,
  isCompanyNameValid,
  isEmailValid,
  isNameValid,
  nameHasError,
  isPhoneValid,
  isCompanyWebsiteValid,
  companyWebsiteHasError,
  phoneHasError,
  cnpjIsValid,
  averageTicketIsValid,
  salesModelIsValid,
  estimatedStudentsIsValid,
} from './validation'
import { usePartnersForm } from '../hooks'

export const Inputs = () => {
  const {
    step,
    information,
    setInformation,
    handleChange,
    handleSubmit,
    handleBackButton,
    errorMessage,
    checkFieldsOnStep1,
    setCheckFieldOnStep1,
    checkFieldsOnStep2,
    setCheckFieldOnStep2,
    checkFieldsOnStep3,
    setCheckFieldOnStep3,
    averageTicket,
    salesModel,
    estimatedStudents,
  } = usePartnersForm()

  const step1 = useMemo(
    () => (
      <InputForm key={1} onSubmit={handleSubmit}>
        <Input
          label="Seu nome"
          placeholder="Lygia Telles"
          width="496px"
          value={information.completeName}
          name="completeName"
          onChange={handleChange}
          isValid={checkFieldsOnStep1 && isNameValid(information)}
          hasError={checkFieldsOnStep1 && nameHasError(information)}
          errorMessage="Infome seu nome e sobrenome"
          variant
          isDarkBackground
          data-testid="input-name"
        />
        <Input
          label="Seu e-mail profissional"
          placeholder="lygia@email.com.br"
          width="496px"
          type="email"
          value={information.email}
          name="email"
          onChange={handleChange}
          isValid={checkFieldsOnStep1 && isEmailValid(information)}
          hasError={checkFieldsOnStep1 && emailHasError(information)}
          errorMessage="Informe um e-mail válido"
          variant
          isDarkBackground
          data-testid="input-email"
        />
        <Input
          label="Telefone"
          placeholder="(11) 95771-2414"
          width="496px"
          type="tel"
          value={information.phone}
          name="phone"
          mask="(99) 99999-9999"
          onChange={handleChange}
          isValid={checkFieldsOnStep1 && isPhoneValid(information)}
          hasError={checkFieldsOnStep1 && phoneHasError(information)}
          errorMessage="É necessário informar um número de celular com DDD"
          variant
          isDarkBackground
          data-testid="input-phone"
        />
        <ButtonWrapper>
          <Button
            text="Próximo"
            color="white"
            icon
            type="submit"
            data-testid="submit-button"
            onClick={(event: React.FormEvent<HTMLFormElement>) =>
              canGoToNextPage({ information, step }) ? handleSubmit(event) : setCheckFieldOnStep1(true)
            }
          />
        </ButtonWrapper>
      </InputForm>
    ),
    [
      checkFieldsOnStep1,
      setCheckFieldOnStep1,
      handleSubmit,
      information,
      setInformation,
      canGoToNextPage,
      step,
      isNameValid,
      nameHasError,
      isEmailValid,
      emailHasError,
      isPhoneValid,
      phoneHasError,
      handleChange,
    ]
  )

  const step2 = useMemo(
    () => (
      <InputForm key={2} onSubmit={handleSubmit}>
        <Select
          width="496px"
          label="Qual é o valor médio dos cursos?"
          variant
          name="averageTicket"
          value={information.averageTicket}
          className={!!checkFieldsOnStep2 && !averageTicketIsValid(information) && 'error'}
          isDarkBackground
          options={averageTicket}
          onChange={handleChange}
        />
        <Select
          width="496px"
          label="Com que frequência você abre novas vendas?"
          variant
          name="salesModel"
          value={information.salesModel}
          className={!!checkFieldsOnStep2 && !salesModelIsValid(information) && 'error'}
          isDarkBackground
          options={salesModel}
          onValueChange={handleChange}
        />
        <Select
          width="496px"
          label="Quantos alunos você pretende efetivar com a Provi em cada lançamento?"
          variant
          name="estimatedStudents"
          value={information.estimatedStudents}
          className={!!checkFieldsOnStep2 && !estimatedStudentsIsValid(information) && 'error'}
          isDarkBackground
          options={estimatedStudents}
          onValueChange={handleChange}
        />
        <Input
          label="Quando será aberta a próxima turma?"
          width="496px"
          value={information.nextLaunchDate}
          name="nextLaunchDate"
          onChange={handleChange}
          type="date"
          variant
          isDarkBackground
        />
        <ButtonWrapper isVariant>
          <TextButton
            text="Voltar"
            color={theme.colors.white100}
            hoverColor={theme.colors.bluePrimaryLight}
            pressedColor={theme.colors.bluePrimaryDark}
            focusedColor={theme.colors.bluePrimaryLight}
            onClick={handleBackButton}
          />
          <Button
            text="Próximo"
            color="white"
            icon
            type="submit"
            data-testid="submit-button"
            onClick={(event: React.FormEvent<HTMLFormElement>) =>
              canGoToNextPage({ information, step }) ? handleSubmit(event) : setCheckFieldOnStep2(true)
            }
          />
        </ButtonWrapper>
      </InputForm>
    ),
    [
      checkFieldsOnStep2,
      setCheckFieldOnStep2,
      handleSubmit,
      information,
      setInformation,
      canGoToNextPage,
      step,
      handleChange,
      handleBackButton,
      averageTicketIsValid,
      salesModelIsValid,
      estimatedStudentsIsValid,
    ]
  )

  const step3 = useMemo(
    () => (
      <InputForm key={3}>
        <Input
          label="Nome da empresa"
          placeholder="Degas, escola criativa"
          width="496px"
          value={information.companyName}
          name="companyName"
          onChange={handleChange}
          isValid={checkFieldsOnStep3 && isCompanyNameValid(information)}
          hasError={checkFieldsOnStep3 && companyNameHasError(information)}
          errorMessage="Informe o nome da empresa"
          variant
          isDarkBackground
          data-testid="company-name"
        />

        <Input
          label="Site da empresa ou perfil em rede social"
          placeholder="https://empresa.com.br"
          width="496px"
          value={information.companyWebsite}
          name="companyWebsite"
          onChange={handleChange}
          isValid={checkFieldsOnStep3 && isCompanyWebsiteValid(information)}
          hasError={checkFieldsOnStep3 && companyWebsiteHasError(information)}
          errorMessage="É necessário informar o site da empresa ou alguma rede social"
          variant
          isDarkBackground
          data-testid="company-site"
        />
        <Input
          label="Cnpj"
          value={information.cnpj}
          placeholder="32.390.384/0001-92"
          mask="99.999.999/9999-99"
          width="496px"
          name="cnpj"
          isValid={checkFieldsOnStep3 && cnpjIsValid(information)}
          hasError={checkFieldsOnStep3 && !cnpjIsValid(information)}
          onChange={handleChange}
          id="cnpj"
          variant
          isDarkBackground
          data-testid="company-hasCNPJ"
        />

        <TOSWarning data-testid="terms">
          Ao enviar o formulário, você concorda com os{' '}
          <NextLink href="/termos-de-uso" passHref legacyBehavior>
            <TOSLink>Termos de uso e Políticas de privacidade</TOSLink>
          </NextLink>{' '}
          da Provi.
        </TOSWarning>
        <ButtonWrapper isVariant>
          <TextButton
            text="Voltar"
            color={theme.colors.white100}
            hoverColor={theme.colors.bluePrimaryLight}
            pressedColor={theme.colors.bluePrimaryDark}
            focusedColor={theme.colors.bluePrimaryLight}
            onClick={handleBackButton}
          />
          <Button
            text="Enviar Respostas"
            onClick={(event: React.FormEvent<HTMLFormElement>) =>
              canGoToNextPage({ information, step }) ? handleSubmit(event) : setCheckFieldOnStep3(true)
            }
            color="white"
            icon
          />
        </ButtonWrapper>
      </InputForm>
    ),
    [
      checkFieldsOnStep3,
      setCheckFieldOnStep3,
      handleSubmit,
      information,
      setInformation,
      canGoToNextPage,
      step,
      handleChange,
      handleBackButton,
      isCompanyNameValid,
      companyNameHasError,
      isCompanyWebsiteValid,
      companyWebsiteHasError,
    ]
  )

  const step4 = useMemo(
    () => (
      <InputForm key={4}>
        <SuccessMessage data-testid="success-message">
          Suas respostas foram enviadas.
          <br />
          Obrigada!
          <br />
        </SuccessMessage>
      </InputForm>
    ),
    [handleSubmit, step]
  )

  const renderStep = useMemo(() => {
    switch (step) {
      case 1:
        return step1
      case 2:
        return step2
      case 3:
        return step3
      case 4:
        return step4
      default:
        return step1
    }
  }, [checkFieldsOnStep1, checkFieldsOnStep2, checkFieldsOnStep3, step, information])

  return (
    <Container>
      <ProgressBar color={theme.colors.white100} progress={(step * 100) / 4} backgroundColor={theme.colors.bluePrimaryDark} />
      {renderStep}
      {!!errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </Container>
  )
}
