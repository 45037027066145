import styled, { keyframes } from 'styled-components'
import { theme } from '@provi/provi-components'

interface IButtonWrapper {
  isVariant?: boolean
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  width: 100vw;
  max-width: 496px;

  ${theme.breakpoints.down('md')} {
    width: 100%;
  }
`

export const ButtonWrapper = styled.div<IButtonWrapper>`
  margin-top: 6px;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: ${({ isVariant }) => (isVariant ? 'space-between' : 'flex-start')};
  align-items: center;

  .MuiButton-contained.Mui-disabled {
    background-color: ${theme.colors.greyLight};

    .MuiButton-label {
      color: ${theme.colors.greyPrimary};
    }
  }
`

const slideInLeft = keyframes`
  from {
    transform: translateX(10%);
    opacity: 0.6;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`

export const InputForm = styled.form`
  margin-top: 34px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;

  transition: all 0.4s ease-in-out;
  animation: ${slideInLeft} 0.2s ease-out;

  #company-average-ticket {
    z-index: 201;
  }

  .error > div {
    border: 2px solid #ff5776;
  }

  > :nth-child(1) {
    z-index: 201 !important;
  }
  > :nth-child(2) {
    z-index: 200 !important;
  }
  > :nth-child(3) {
    z-index: 199 !important;
  }
`

export const SuccessMessage = styled.p`
  max-width: 360px;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.005em;
  color: ${theme.colors.white100};
  align-self: flex-start;

  button {
    margin-top: 35px;
  }
`

export const ErrorMessage = styled.span`
  margin-top: 16px;
  color: ${theme.colors.white100};
  align-self: center;
`

export const TOSWarning = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.0075em;
  color: #ffffff;

  ${theme.breakpoints.down('sm')} {
    font-size: 13.2px;
    line-height: 20px;
  }
`

export const TOSLink = styled.a`
  text-decoration: underline;
  letter-spacing: 0.005em;
  font-weight: 600;
  color: inherit;
`

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-start;
  gap: 16px;
  padding: 0 0 0 24px;
  cursor: pointer;
  position: relative;

  :hover {
    input ~ span {
      background-color: ${theme.colors.bluePrimaryLight};
    }
  }

  :active {
    input ~ span {
      background-color: ${theme.colors.blueSecundaryDark};
    }
  }

  input:checked ~ span {
    background-color: ${theme.colors.bluePrimaryLight};
  }

  input:checked ~ span:after {
    display: block;
  }

  span:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid ${theme.colors.bluePrimaryDark};
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  :focus,
  :focus-within {
    outline: 2px auto ${theme.colors.bluePrimary};
    outline-offset: 2px;
  }
`

export const Checkbox = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`
export const ShownCheckbox = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: #fff;
  border-radius: 2px;

  :after {
    content: '';
    position: absolute;
    display: none;
  }
`

export const CheckboxLabel = styled.label`
  color: #ffffff;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.0075em;
  padding-left: 8px;
  cursor: pointer;

  :hover {
    color: ${theme.colors.bluePrimaryLight};
  }

  :active {
    color: ${theme.colors.blueSecundaryDark};
  }

  ${theme.breakpoints.down('sm')} {
    margin-top: 2px;
    font-size: 13.2px;
    line-height: 20px;
    letter-spacing: 0.005em;
  }
`

export const SelectWrapper = styled.div`
  width: 100%;
  label {
    color: #ffffff;
  }
`
