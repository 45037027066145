import { Breadcrumbs, theme } from '@provi/provi-components'
import { IBreadcrumb } from 'types'
import { Container, BannerTitle, A } from './styles'
import NextLink from 'next/link'

interface IBanner {
  backgroundColor: string
  backgroundURL: string
  title: string
  breadcrumbsProps?: IBreadcrumb
  isVariant?: boolean
}

const handleBreadcrumpsProps = (breadcrumbsProps: IBreadcrumb) => {
  const { levels } = breadcrumbsProps
  const newLevels: any[] = []
  levels.forEach((level: string) => {
    if (level === 'Início') {
      return newLevels.push(
        <NextLink href="/" passHref legacyBehavior>
          <A>{level}</A>
        </NextLink>
      )
    }
    newLevels.push(level)
  })
  return newLevels
}

export const SingleBanner = ({ backgroundColor, backgroundURL, title, breadcrumbsProps, isVariant }: IBanner): JSX.Element => (
  <Container
    backgroundURL={backgroundURL}
    backgroundColor={backgroundColor}
    hasBreadCrumbs={!!breadcrumbsProps}
    isVariant={isVariant}
    className="banner-container"
  >
    {breadcrumbsProps && (
      <Breadcrumbs
        levels={handleBreadcrumpsProps(breadcrumbsProps)}
        color={breadcrumbsProps?.color || '#000000'}
        currentColor={breadcrumbsProps?.currentColor || theme.colors.bluePrimaryDark}
      />
    )}
    <BannerTitle isVariant={isVariant}>{title}</BannerTitle>
  </Container>
)
