import { useCallback, useState } from 'react'
import { ISendPartnerForm } from 'types'
import { sendPartnerForm } from '~/lib/hubspot'
import { event as gtag } from '~/lib/utils'

export const usePartnersForm = () => {
  const [step, setStep] = useState(1)
  const [errorMessage, setErrorMessage] = useState('')
  const [checkFieldsOnStep1, setCheckFieldOnStep1] = useState(false)
  const [checkFieldsOnStep2, setCheckFieldOnStep2] = useState(false)
  const [checkFieldsOnStep3, setCheckFieldOnStep3] = useState(false)

  const [information, setInformation] = useState<ISendPartnerForm>({
    completeName: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    companyName: '',
    companyWebsite: '',
    cnpj: '',
    averageTicket: undefined,
    salesModel: undefined,
    estimatedStudents: undefined,
    nextLaunchDate: '',
  })

  const handleChange = useCallback(
    (event: any) => {
      let name
      let value
      if (event.target) {
        name = event.target.name
        value = event.target.value

        if (name === 'completeName') {
          const splitedName = value.split(' ')
          const firstName = splitedName[0]
          const lastName = splitedName.slice(1).join(' ')

          setInformation({
            ...information,
            [name]: value,
            firstName,
            lastName,
          })

          return
        }
      } else {
        name = event.name
        value = event.value

        setInformation({
          ...information,
          [name]: event,
        })
        return
      }

      setInformation({
        ...information,
        [name]: value,
      })
    },
    [information, setInformation]
  )

  const handleBackButton = useCallback(() => {
    setErrorMessage('')
    setStep(step - 1)
  }, [step, setStep, setErrorMessage])

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target

    setInformation({
      ...information,
      [name]: checked,
    })
  }

  const getPageUri = useCallback(() => window.location.href || '', [])

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      setErrorMessage('')

      if (step === 3) {
        const pageUri = getPageUri()
        const hubspotBody: ISendPartnerForm = {
          ...information,
        }

        const response = await sendPartnerForm(hubspotBody)

        if (response.status !== 200) {
          const message = response?.data?.error?.error[0]?.message
          setErrorMessage(message || 'Ocorreu um erro inesperado, tente novamente mais tarde')
          return
        }

        gtag({
          action: 'generate_lead',
          category: 'partner_form',
          label: pageUri,
          value: 1,
        })
      }

      setStep(step + 1)
    },
    [step, information, errorMessage, setErrorMessage]
  )

  const averageTicket = [
    { name: 'averageTicket', label: 'Até R$300', value: 'Up to 300' },
    { name: 'averageTicket', label: 'De R$301 a R$1.500', value: 'Between 301 and 1500' },
    { name: 'averageTicket', label: 'De R$1.501 a R$3000', value: 'Between 1501 and 3000' },
    { name: 'averageTicket', label: 'De R$3.001 a R$5.000', value: 'Between 3001 and 5000' },
    { name: 'averageTicket', label: 'De R$5.001 a R$10.000', value: 'Between 5001 and 10000' },
    { name: 'averageTicket', label: 'Mais que R$10.000', value: 'More than 10001' },
    { name: 'averageTicket', label: 'Não definido', value: 'Not set yet' },
  ]

  const salesModel = [
    { name: 'salesModel', label: 'Vendas com datas de lançamento', value: 'Perpetual' },
    { name: 'salesModel', label: 'Vendas do tipo perpétuo', value: 'Launch' },
    { name: 'salesModel', label: 'Vendas no perpétuo e no lançamento', value: 'Mixed' },
  ]

  const estimatedStudents = [
    { name: 'estimatedStudents', label: 'Entre 11 e 30', value: 'Between 11 and 30' },
    { name: 'estimatedStudents', label: 'Entre 31 e 50', value: 'Between 31 and 50' },
    { name: 'estimatedStudents', label: 'Até 10', value: 'Up to 10' },
    { name: 'estimatedStudents', label: 'Entre 51 e 100', value: 'Between 51 and 100' },
    { name: 'estimatedStudents', label: 'Entre 101 e 500', value: 'Between 101 and 500' },
    { name: 'estimatedStudents', label: 'Mais de 500', value: 'More than 500' },
  ]

  // date format :AAAA-MM-DD

  return {
    step,
    setStep,
    information,
    setInformation,
    handleChange,
    handleCheckboxChange,
    handleSubmit,
    handleBackButton,
    errorMessage,
    setErrorMessage,
    checkFieldsOnStep1,
    setCheckFieldOnStep1,
    checkFieldsOnStep2,
    setCheckFieldOnStep2,
    checkFieldsOnStep3,
    setCheckFieldOnStep3,
    averageTicket,
    salesModel,
    estimatedStudents,
  }
}
