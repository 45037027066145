import { isEmailValid as emailValid, isCNPJValid } from '~/lib/utils'
import { ISendPartnerForm } from 'types'

export const isCompanyNameValid = (information: ISendPartnerForm): boolean => information.companyName.length >= 2

export const companyNameHasError = (information: ISendPartnerForm): boolean => !information.companyName.length

export const isNumberOfEmployeesValid = (numberOfEmployees: string): boolean => !!numberOfEmployees.length

const regex = /^[a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ\-\s]*$/
export const isNameValid = (information: ISendPartnerForm) =>
  information.completeName.length >= 2 && regex.test(information.completeName)

export const nameHasError = (information: ISendPartnerForm) =>
  information.completeName.length < 2 || !regex.test(information.completeName)

export const isEmailValid = (information: ISendPartnerForm) => emailValid(information.email)

export const emailHasError = (information: ISendPartnerForm) => !emailValid(information.email)

export const isPhoneValid = (information: ISendPartnerForm) => {
  const phone = information.phone.replace(/\D/g, '')

  if (phone.length === 11) {
    return true
  }

  return false
}

export const phoneHasError = (information: ISendPartnerForm) => {
  const phone = information.phone.replace(/\D/g, '')

  if (phone.length === 11) {
    return false
  }

  return true
}

export const isCompanyWebsiteValid = (information: ISendPartnerForm) => {
  const url = information.companyWebsite.replace(/\s/g, '')

  if (url.length > 1) {
    return true
  }

  return false
}

export const companyWebsiteHasError = (information: ISendPartnerForm) => {
  const url = information.companyWebsite.replace(/\s/g, '')

  if (url.length > 1) {
    return false
  }

  return !url.length
}

export const cnpjIsValid = (information: ISendPartnerForm) => {
  const cnpj = information.cnpj.replace(/\s/g, '')

  return isCNPJValid(cnpj)
}

export const averageTicketIsValid = (information: ISendPartnerForm) => !!information.averageTicket

export const salesModelIsValid = (information: ISendPartnerForm) => !!information.salesModel

export const estimatedStudentsIsValid = (information: ISendPartnerForm) => !!information.estimatedStudents
interface ICanGoToNextPage {
  information: ISendPartnerForm
  step: number
}

export const canGoToNextPage = ({ information, step }: ICanGoToNextPage) => {
  switch (step) {
    case 1:
      return isNameValid(information) && isEmailValid(information) && isPhoneValid(information)
    case 2:
      return averageTicketIsValid(information) && salesModelIsValid(information) && estimatedStudentsIsValid(information)
    case 3:
      return isCompanyNameValid(information) && isCompanyWebsiteValid(information) && cnpjIsValid(information)
    default:
      return false
  }
}
