import { ReactNode } from 'react'
import Image from 'next/legacy/image'
import { SectionWrapper, Container, FirstPart, SecondPart, Tag, Heading2, Content } from './styles'

interface ISectionWithImage {
  title?: string
  content: ReactNode | JSX.Element | string
  image: string
  imagePosition: 'left' | 'right'
  imageOffsetX?: number
  imageOffsetY?: number
  tag?: string
  alt: string
  minHeight?: string
  isVariant?: boolean
  isDark?: boolean
}

export const SectionWithImage = ({
  title = '',
  content = '',
  image = '',
  imagePosition = 'left',
  imageOffsetX = 0,
  imageOffsetY = 0,
  tag = '',
  alt = '',
  minHeight = '',
  isVariant = false,
  isDark = false,
}: ISectionWithImage) => (
  <SectionWrapper isDark={isDark}>
    <Container
      imagePosition={imagePosition}
      imageOffsetX={imageOffsetX}
      minHeight={minHeight}
      isVariant={isVariant}
      className="section-container"
    >
      <FirstPart>
        <Image
          src={image}
          alt={alt}
          layout="intrinsic"
          style={{ objectFit: 'cover' }}
          width={`${496 + imageOffsetX}`}
          height={`${400 + imageOffsetY}`}
          quality={100}
        />
      </FirstPart>
      <SecondPart>
        {tag && <Tag isDark={isDark}>{tag}</Tag>}
        {title && <Heading2 isDark={isDark}>{title}</Heading2>}
        <Content isDark={isDark}>{content}</Content>
      </SecondPart>
    </Container>
  </SectionWrapper>
)
