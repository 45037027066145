const setEnvironment = () => ({
  MS_SITE: process.env.NEXT_PUBLIC_MS_SITE,
  MS_BLOG: process.env.NEXT_PUBLIC_MS_BLOG,
  CLOUD_URL: process.env.NEXT_PUBLIC_CLOUD_URL,
  FIREBASE_API_KEY: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  SMART_LOOK: process.env.SMART_LOOK,
  MS_INTEGRATIONS_URL: process.env.NEXT_PUBLIC_MS_INTEGRATIONS_URL,
  MS_INTEGRATIONS_TOKEN: process.env.NEXT_PUBLIC_MS_INTEGRATIONS_TOKEN,
  WORDPRESS_GRAPHQL: process.env.NEXT_PUBLIC_WORDPRESS_GRAPHQL,
  URL: process.env.NEXT_PUBLIC_URL,
})

export const ROOT_URL = setEnvironment()
