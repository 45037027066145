import { PartnersContainer, FormSection, FormInformation, FormTitle, FormDescription } from './styles'
import { Inputs } from './Inputs/Inputs'

const PartnersForm = () => (
  <PartnersContainer>
    <FormSection>
      <FormInformation>
        <FormTitle>Seja parceiro da Provi</FormTitle>
        <FormDescription>Descubra como podemos te ajudar a acelerar o seu negócio.</FormDescription>
      </FormInformation>
      <Inputs />
    </FormSection>
  </PartnersContainer>
)

export default PartnersForm
